import dotenv from 'dotenv';

dotenv.config();

const config = {
  api: {
    baseUrl: process.env.REACT_APP_BASEURL,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    audienceUrl: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  rollBar: {
    accessToken: process.env.REACT_APP_ROLLBARTOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    },
  },
  map: {
    accessToken: process.env.REACT_APP_GOOGLEMAPSKEY,
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLECLIENTID || '',
  },
  services: {
    waterMeters: process.env.REACT_APP_WATERMETERS || '',
  },
};

export default config;