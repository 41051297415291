import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from 'services/api';

const Coordinates = types.model("coordinates").props({ coordinates: types.array(types.number), });

const AlarmsModel = types.model("Alarms").props({
  address: types.maybeNull(types.string),
  serial: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  battery: types.maybeNull(types.number),
  connected: types.maybeNull(types.boolean),
  heightColor: types.maybeNull(types.string),
  heightContainer: types.maybeNull(types.number),
  heightPercentage: types.maybeNull(types.number),
  heightLimit1: types.maybeNull(types.number),
  heightLimit2: types.maybeNull(types.number),
  location: types.maybeNull(Coordinates),
  group: types.maybeNull(types.string),
  senseValue: types.maybeNull(types.number),
  temperature: types.maybeNull(types.number),
  senseValue_m: types.maybeNull(types.number),
  heightLevel1: types.maybeNull(types.number),
  heightLevel2: types.maybeNull(types.number),
  areaContainer_m2: types.maybeNull(types.number),
  heightContainerMeter: types.maybeNull(types.number),
  heightContent: types.maybeNull(types.number),
  heightContentPercentage: types.maybeNull(types.number),
  heightLimit1Porcentage: types.maybeNull(types.number),
  heightLimit2Porcentage: types.maybeNull(types.number),
  volContent_liters: types.maybeNull(types.number),
});

const Location = types.model("Location").props({
  lat: types.number,
  lng: types.number,
});

export const LevelSensorModelAlarm = types
  .model("LevelSensor")
  .props({
    alarms: types.optional(types.array(AlarmsModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    isLoading: types.boolean,
    idLoading: types.maybeNull(types.string),
    center: Location,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase()) ||
          alarm.address?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setlocationSelected(cordinates: any) {
      self.locationSelected = cordinates;
    }
    function setId(id: any) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    function setIsLoading(isLoading: boolean, id: string | null) {
      self.idLoading = id;
      self.isLoading = isLoading;
    }
    function setLoading(loading: any) {
      self.isLoading = loading;
    }
    function setCenter(coordinates: any) {
      self.center = coordinates;
    }
    return {
      setAlarms,
      setlocationSelected,
      setCenter,
      setIsLoading,
      setLoading,
      setId,
    };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.levelsensor;
        const response = await alarmsApi.getDevice();
        self.setAlarms(response?.devices);
        self.setCenter(response?.center);
        self.setLoading(false);
        self.setIsLoading(false, null);
      } catch (error) {
        console.log(error);
      }
    },
    sendAction: async (payload: any) => {
      try {
        const levelsensorApi = api.levelsensor;
        await levelsensorApi.changeStatus(payload);
        self.setIsLoading(true, payload.serial);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type LevelSensorModelType = Instance<typeof LevelSensorModelAlarm>;
export type LevelSensor = LevelSensorModelType;
type LevelSensorSnapshotType = SnapshotOut<typeof LevelSensorModelAlarm>;
export type LevelSensorSnapshot = LevelSensorSnapshotType;
export const levelsensorDefaultModel = () =>
  types.optional(LevelSensorModelAlarm, {
    alarms: [],
    locationSelected: null,
    idSelected: null,
    isLoading: false,
    idLoading: null,
    center: { lat: 0, lng: 0 },
  });