import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Provider as RoolbarProvider, ErrorBoundary } from '@rollbar/react';
import { Provider } from 'react-redux';

import App from './App';
import ErrorBoundaryUI from 'components/common/error';

import { pageViewTracking, run } from 'config/ga';
import { RootStoreProvider, rootStore } from 'models';
import config from './config';
import store from './store';

export const ConnectedApp = () => {
  // Initialize Google Analytics
  run();

  pageViewTracking({
    pageview: window.location.pathname + window.location.search,
  });

  const onRedirectCallback = (appState: AppState | undefined) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  return (
    <RoolbarProvider config={config.rollBar}>
      <ErrorBoundary fallbackUI={ErrorBoundaryUI}>
        <Provider store={store}>
          <RootStoreProvider value={rootStore}>
            <Auth0Provider
              domain={config.auth0.domain!}
              clientId={config.auth0.clientId!}
              authorizationParams={{
                redirect_uri: `${window.location.origin}/dashboard`,
                audience: config.auth0.audienceUrl,
              }}
              onRedirectCallback={onRedirectCallback}
            >
              <App />
            </Auth0Provider>
          </RootStoreProvider>
        </Provider>
      </ErrorBoundary>
    </RoolbarProvider>
  );
};
