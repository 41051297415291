import { createStore } from 'redux';

const initialState = {
  sidebarShow: true,
};

const changeState = (
  state = initialState,
  {
    type, ...rest
  }: {
    type: string; [key: string]: any
  }
) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
