import { Api } from '.';
import type { CreateUser, UpdateUser } from 'views/users/helpers';

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getUsers(): Promise<any> {
    try {
      const response = await this.api.apisauce.get(`/users`);
      if (!response.ok) {
        return response.problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createUser(user: CreateUser): Promise<any> {
    try {
      const response = await this.api.apisauce.post(`/users`, user);
      if (!response.ok) {
        return { kind: 'bad-data' };
      }
      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateUser(id: string, user: UpdateUser): Promise<any> {
    try {
      const response = await this.api.apisauce.patch(`/users/${id}`, user);
      if (!response.ok) {
        return {
          kind: 'bad-data',
          message: response.data || 'Error updating user'
        };
      }
      return response.data;
    } catch (e) {
      return {
        kind: 'bad-data',
        message: 'Network error while updating user'
      };
    }
  }
}
