import { ApiResponse } from "apisauce";
import { Alarm } from "models/interfaces/alarm";
import { Api } from ".";
import { getGeneralApiProblem } from "./api-problem";

export class AlarmApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAlarms(params?: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        "/alarm",
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async postComment(payload: Alarm): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/alarm`,
        payload
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async closeAlarm(payload: Alarm): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `alarm/${payload.serial}/close`,
        { message: payload.message }
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
