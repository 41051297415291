import { ApiResponse } from "apisauce";
import { IUser } from "models/interfaces/user";
import { Api } from ".";
import { getGeneralApiProblem } from "./api-problem";

export class LoginApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getUser(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `users/me`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async login(usuario: IUser): Promise<any> {
    const payload = {
      username: usuario.username,
      password: usuario.password,
      clientId: usuario.clientId,
    };

    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `users/login`,
        payload
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}
