import {
  types,
  Instance,
  SnapshotOut,
  IMSTArray,
  ISimpleType,
} from 'mobx-state-tree';

import { IUser } from 'models/interfaces/user';
import api from 'services/api';

export const UsersModel = types
  .model('User')
  .props({
    token: types.maybeNull(types.string),
    user: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    client: types.maybeNull(types.string),
    services: types.maybeNull(types.array(types.string)),
    error: types.boolean,
    app: types.maybeNull(types.number),
    footer: types.maybeNull(types.array(types.string)),
  })
  .actions((self) => {
    function setUser(payload: {
      user: string;
      token: string;
      type: string;
      client: string;
      services: IMSTArray<ISimpleType<string>>;
      app: number;
      footer: IMSTArray<ISimpleType<string>>;
    }) {
      self.user = payload.user;
      self.token = payload.token;
      self.type = payload.type;
      self.client = payload.client;
      self.services = payload.services;
      self.app = payload.app;
      self.footer = payload.footer;
    }

    function setToken(token: string) {
      self.token = token;
    }

    function getToken() {
      return self.token;
    }

    function removeToken() {
      self.token = null;
      api.api.removeToken();
    }

    function setError(error: boolean) {
      self.error = error;
    }

    return {
      setUser,
      setToken,
      getToken,
      removeToken,
      setError,
    };
  })
  .actions((self) => ({
    login: async (usuario: IUser) => {
      self.setError(false);
      try {
        const userApi = api.login;
        const payload = {
          username: usuario.username,
          password: usuario.password,
          clientId: usuario.clientId,
        };
        const response = await userApi.login(payload);
        const errorTypes = ['cannot-connect', 'unauthorized'];

        if (errorTypes.includes(response.kind)) {
          self.setError(true);
        }

        api.api.setToken(response.token);
        self.setUser(response);
      } catch (error) {
        self.setError(true);
      }
    },
    setSSO: async (token: string) => {
      self.setError(false);
      try {
        api.api.setToken(token);

        const userApi = api.login;
        const response = await userApi.getUser();

        self.setUser(response);
        self.setToken(token);
      } catch (error) {
        self.setError(true);
      }
    },
    getUsers: async () => {
      self.setError(false);
      try {
        const response = await api.user.getUsers();
        return response;
      } catch (error) {
        self.setError(true);
      }
    },
  }));

type UsersModelType = Instance<typeof UsersModel>;
export type Users = UsersModelType;
type UsersSnapshotType = SnapshotOut<typeof UsersModel>;
export type UsersSnapshot = UsersSnapshotType;
export const userDefaultModel = () =>
  types.optional(UsersModel, {
    user: null,
    client: null,
    token: null,
    services: null,
    error: false,
  });
