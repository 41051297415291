import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from 'services/api';

export const FrameModel = types
  .model("iFrame")
  .props({
    iframeUrl: types.maybeNull(types.string),
  })
  .actions((self) => {
    function setFrame(payload: any) {
      self.iframeUrl = payload.iframeUrl;
    }
    return {
      setFrame,
    };
  })
  .actions((self) => ({
    getFrame: async (id: string) => {
      try {
        const MetaBase = api.reports;
        const response = await MetaBase.getReportById(id);
        self.setFrame(response);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type FrameModelType = Instance<typeof FrameModel>;
export type Frames = FrameModelType;
type FrameSnapshotType = SnapshotOut<typeof FrameModel>;
export type FrameSnapshot = FrameSnapshotType;
export const frameDefaultModel = () =>
  types.optional(FrameModel, {
    iframeUrl: "",
  });
