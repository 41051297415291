import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { LogoutOptions } from '@auth0/auth0-react';

import { ApiConfig } from './api-config';
import { AlarmApi } from './alarmApi';
import { LoginApi } from './loginApi';
import { ReportsApi } from './reportApi';
import { CustomApi } from './baseApi';
import { CardsApi } from './cardsApi';
import { MapApi } from './mapApi';
import { MceApi } from './mceApi';
import { CEVTPTApi } from './cevtprApi';
import { RoadsignApi } from './roadsignApi';
import { EnergyMonitorApi } from './energy_monitorApi';
import { LevelSensorApi } from './levelsensorApi';
import { LoopsApi } from './loopsApi';
import { getGeneralApiProblem } from './api-problem';
import { WaterApi } from './waterApi';
import { rootStore } from 'models';
import { UserApi } from './userApi';
import { ClientApi } from './clientApi';

const API_URL = process.env.REACT_APP_BASEURL || '';

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 100000,
};

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  token: string | null;

  auth0Logout?: (options?: LogoutOptions | undefined) => void;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json'
      },
    });
  }

  removeToken() {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json'
    });
    this.apisauce.deleteHeader('Authorization');
  }

  setToken(token: string) {
    this.token = token;
    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    });
  }

  async getCard(url: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) { return { kind: 'bad-data' }; }
  }

  async getGroup(service: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.apisauce.get(
        `${service}/groups`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      console.log(data)
      return data;
    } catch (error) { return { kind: 'bad-data' }; }
  }

  setAuth0Logout(auth0Logout: () => void) {
    this.auth0Logout = auth0Logout;
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  parking: new CustomApi(baseApi, 'parking'),
  sense_4_20mA: new CustomApi(baseApi, 'sense_4_20mA'),
  transformer: new CustomApi(baseApi, 'transformer'),
  transit: new CustomApi(baseApi, 'loop'),
  alarms: new AlarmApi(baseApi),
  login: new LoginApi(baseApi),
  gas: new CustomApi(baseApi, 'gas'),
  dw: new CustomApi(baseApi, 'gateway'),
  energy_efficiency: new CustomApi(baseApi, 'energy_efficiency'),
  reports: new ReportsApi(baseApi),
  cards: new CardsApi(baseApi),
  maps: new MapApi(baseApi),
  mce: new MceApi(baseApi),
  cevtpr: new CEVTPTApi(baseApi),
  roadsign: new RoadsignApi(baseApi),
  energymonitor: new EnergyMonitorApi(baseApi),
  levelsensor: new LevelSensorApi(baseApi),
  loops: new LoopsApi(baseApi),
  water: new WaterApi(baseApi, 'water'),
  freezer: new CustomApi(baseApi, 'freezer'),
  lake: new CustomApi(baseApi, 'river'),
  weather: new CustomApi(baseApi, 'weather'),
  residential: new CustomApi(baseApi, 'residentialWaterMeters'),
  user: new UserApi(baseApi),
  client: new ClientApi(baseApi),
};

const responseMonitor = async (response: any) => {
  if (response.status === 403 || response.status === 401) {
    if (api.api.auth0Logout) {
      api.api.removeToken();
      rootStore.usersStore.removeToken();
      api.api.auth0Logout({
        logoutParams: { returnTo: window.location.origin }
      });
    }
  }
};

api.api.apisauce.addMonitor(responseMonitor);

export default api;
