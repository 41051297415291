import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'react-app-polyfill/stable';
import 'core-js';

import { ConnectedApp } from './ConnectedApp';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  </StrictMode>
);
