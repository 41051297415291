import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from 'services/api';

const Coordinates = types.model("coordinates").props({
  coordinates: types.array(types.number),
});

const AlarmModel = types.model("Alarm").props({
  address: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  serial: types.maybeNull(types.string),
  location: types.maybeNull(Coordinates),
  connected: types.maybeNull(types.boolean),
  connectedDate: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  fase1: types.maybeNull(types.number),
  fase2: types.maybeNull(types.number),
  fase3: types.maybeNull(types.number),
  tempOptical: types.maybeNull(types.number),
  tempWeather: types.maybeNull(types.number),
  energyAlert: types.maybeNull(types.boolean),
});

const Location = types.model("Location").props({
  lat: types.number,
  lng: types.number,
});

export const TransformerModel = types
  .model("Transformer")
  .props({
    alarms: types.optional(types.array(AlarmModel), []),
    locationSelected: types.maybeNull(Location),
    idSelected: types.maybeNull(types.string),
    center: Location,
    isLoading: types.boolean,
  })
  .views((self) => ({
    filterValues(value: string | null) {
      if (value === null) return self.alarms;
      return self.alarms.filter(
        (alarm) =>
          alarm.id?.toLowerCase().includes(value.toLowerCase()) ||
          alarm.address?.toLowerCase().includes(value.toLowerCase())
      );
    },
  }))
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setlocationSelected(cordinates: { lng: number; lat: number }) {
      self.locationSelected = cordinates;
    }
    function setId(id: string) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    function setCenter(center: any) {
      self.center = center;
    }
    function setLoading(loading: boolean) {
      self.isLoading = loading;
    }
    return {
      setAlarms,
      setLoading,
      setCenter,
      setlocationSelected,
      setId,
    };
  })
  .actions((self) => ({
    getDevices: async () => {
      try {
        self.setLoading(true);
        const alarmsApi = api.transformer;
        const response = await alarmsApi.getDevice();
        self.setCenter(response?.center);
        self.setAlarms(response?.devices);
        self.setLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
  }));

type TransformerModelType = Instance<typeof TransformerModel>;
export type Transformer = TransformerModelType;
type TransformerSnapshotType = SnapshotOut<typeof TransformerModel>;
export type TransformerSnapshot = TransformerSnapshotType;
export const transformerDefaultModel = () =>
  types.optional(TransformerModel, {
    alarms: [],
    locationSelected: null,
    idSelected: null,
    isLoading: false,
    center: { lat: 0, lng: 0 },
  });
