import { ApiResponse, create } from "apisauce";
import { Api } from ".";
import { getGeneralApiProblem } from "./api-problem";

export class WaterApi {
  private api: Api;
  service: string;

  constructor(api: Api, service: string) {
    this.api = api;
    this.service = service;
  }

  async getDevice(params: {} | undefined): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getGroupGroups(service: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${service}/group/groups`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getTableData(params: {} | undefined): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/group`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getChartData(params: {} | undefined): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/chart`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }
  async getChartDataById(
    id: string | undefined,
    params?: {} | undefined
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/chart/${id}`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getDeviceById(id?: string, params?: {}): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device/${id}`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getDeviceDetailById(id?: string, params?: {}): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device/${id}/detail`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async getDeviceHistoricById(
    id: string | undefined,
    params?: {}
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device/${id}/historic`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }
  async getMetricsById(id: string | undefined, params?: {}): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/device/${id}/metrics`,
        params
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }

  async syncronizeDevice(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `${this.service}/syncronize`
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response;
    } catch (error) {
      return { kind: "bad-data" };
    }
  }
}
