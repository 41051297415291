import { types, Instance, SnapshotOut } from "mobx-state-tree";
import api from "services/api";

const Comment = types.model("Comment").props({
  user: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
});

const AlarmModel = types.model("AlarmType").props({
  criticality: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  failure: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  serial: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  closingReason: types.maybeNull(types.string),
  comment: types.maybeNull(types.array(Comment)),
  group: types.maybeNull(types.string),
});

export const AlarmsModel = types
  .model("Alarms")
  .props({
    alarms: types.optional(types.array(AlarmModel), []),
    idSelected: types.maybeNull(types.string),
  })
  .actions((self) => {
    function setAlarms(alarms: any) {
      self.alarms = alarms;
    }
    function setId(id: any) {
      self.idSelected = self.idSelected === id ? null : id;
    }
    return {
      setId,
      setAlarms,
    };
  })
  .actions((self) => ({
    getMeAlarms: async (params?: any) => {
      try {
        const alarmsApi = api.alarms;
        const response = await alarmsApi.getAlarms(params);
        self.setAlarms(response);
      } catch (error) {
        console.log(error);
      }
    },
    postComment: async (alarm: any) => {
      try {
        const alarmsApi = api.alarms;
        const payload = {
          serial: alarm.serial,
          comment: alarm.comment,
        };
        await alarmsApi.postComment(payload);
      } catch (error) {
        console.log(error);
      }
    },
    closeAlarm: async (alarm: any) => {
      try {
        const alarmsApi = api.alarms;
        const payload = {
          serial: alarm.serial,
          message: alarm.message,
        };
        await alarmsApi.closeAlarm(payload);
      } catch (error) {
        console.log(error);
      }
    },
  }));

type AlarmsModelType = Instance<typeof AlarmsModel>;
export type Alarms = AlarmsModelType;
type AlarmModelType = Instance<typeof AlarmModel>;
export type Alarm = AlarmModelType;
type AlarmsSnapshotType = SnapshotOut<typeof AlarmsModel>;
export type AlarmsSnapshot = AlarmsSnapshotType;
export const alarmsDefaultModel = () =>
  types.optional(AlarmsModel, {
    alarms: [],
  });
