import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { rootStore } from 'models';
import api from 'services/api';

interface UseAuthReturn {
  isPendingToken: boolean;
}

/**
 * Custom hook that manages authentication state and token handling using Auth0.
 *
 * @returns {UseAuthReturn} An object containing the authentication state.
 *
 * @remarks
 * This hook integrates with Auth0 for authentication and manages the authentication token.
 * It provides functionality to fetch, set, and remove the authentication token.
 * It also handles user logout and checks user roles.
 *
 * @example
 * ```tsx
 * const { isPendingToken } = useAuth();
 * ```
 *
 * @internal
 * This hook is intended to be used internally within the application.
 */
export const useAuth = (): UseAuthReturn => {
  const [isPendingToken, setIsPendingToken] = useState(true);
  const { setToken, getToken } = rootStore.usersStore;
  const {
    getAccessTokenSilently,
    logout,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  const setAuthToken = useCallback((authToken: string) => {
    api.api.setToken(authToken);
    setToken(authToken);
    setIsPendingToken(false);
  }, [setToken]);

  const fetchAuthToken = useCallback(async () => {
    const token = getToken();
    if (token) {
      setAuthToken(token);
    } else if (isAuthenticated) {
      try {
        const authToken = await getAccessTokenSilently();
        if (authToken) {
          setAuthToken(authToken);
        }
      } catch (error) {
        if (
          typeof error === 'object' &&
          error !== null &&
          'error' in error &&
          error.error === 'login_required'
        ) {
          loginWithRedirect();
        } else {
          console.error('Failed to get access token silently', error);
        }
      }
    } else {
      setIsPendingToken(true);
    }
  }, [getAccessTokenSilently, getToken, setAuthToken, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    api.api.setAuth0Logout(logout);
    fetchAuthToken();
  }, [fetchAuthToken, logout]);

  return { isPendingToken };
};
