// External Modules
import React from 'react';
import { observer } from 'mobx-react-lite';
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFireFlameSimple,
  faCar,
  faMoon,
  faSignsPost,
  faE,
  faCloud,
  fa3,
  fas,
  faSquareArrowUpRight,
  faArrowUp19,
} from '@fortawesome/free-solid-svg-icons';
import { CSpinner } from '@coreui/react';
import persist from 'mst-persist';

// Internal Modules
import { rootStore, useStores } from 'models';
import { useAuth } from 'hooks/useAuth';
import api from 'services/api';

import './scss/style.scss';

const LoadingScreen = () => (
  <div className='d-flex justify-content-center align-items-center vh-100'>
    <CSpinner color='primary' />
  </div>
);

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));

const App = observer(() => {
  library.add(
    faFireFlameSimple,
    faCar,
    faMoon,
    faSignsPost,
    faE,
    faCloud,
    fa3,
    fas
  );

  const { isLoading: isAuth0Loading } = useAuth0();
  const { isPendingToken } = useAuth();

  persist('iotinmotion', rootStore).then(() => {
    if (rootStore.usersStore.token && rootStore.usersStore.token !== '') {
      api.api.apisauce.headers.Authorization = `Bearer ${rootStore.usersStore.token}`;
    }
  });

  if (isAuth0Loading) {
    return <LoadingScreen />;
  }

  return (
    <BrowserRouter>
      <React.Suspense fallback={<LoadingScreen/>}>
        <Routes>
          {isPendingToken ? (
            <Route path='/*' element={<Login />} />
          ) : (
            <Route path='/*' element={<DefaultLayout />} />
          )}
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
});

export default App;
