import { Api } from '.';

export class ClientApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getClients(): Promise<any> {
    try {
      const response = await this.api.apisauce.get(`/clients`);
      if (!response.ok) {
        return response.problem;
      }
      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getClient(id: string): Promise<any> {
    try {
      const response = await this.api.apisauce.get(`/clients/${id}`);
      if (!response.ok) {
        return response.problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
